import { Typography } from '@material-tailwind/react';
import classNames from 'classnames';
import { capitalize, filter, set } from 'lodash';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  elements,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Button from 'src/app/ui/Button';
import Variants from 'src/app/ui/variants';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import SelectInput from 'src/app/ui/SelectInput';
import { BENCHMARK } from 'src/app/core/consts/benchmark';
import { useUser } from 'src/app/core/feature-user/provider/userProvider';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export interface Capacity {
  aspect: string;
  value: number;
}

const ProgressChart = ({ porcentage }: { porcentage: number }) => {
  const empty = 100 - porcentage;

  return (
    <svg width="80px" height="80px" viewBox="0 0 42 42" className="donut">
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="rgba(255, 255, 255, 0.10)"
        strokeWidth="4"
      ></circle>
      {porcentage > 0 && (
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#0069FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray={`${porcentage} ${empty}`}
          strokeDashoffset="0"
        ></circle>
      )}
    </svg>
  );
};

const StyledDot = styled.span`
  display: block;
  width: 13.33px;
  height: 13.33px;
  border-radius: 50%;
`;

const StyledCard = styled.div.attrs({
  className: 'bg-white/5',
})`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.04);
`;

const CapacityCard = ({ capacity, isQuizCompleted }: any) => {
  const backgrounds = [
    'bg-gray-500',
    'bg-red-200',
    'bg-orange-300',
    'bg-yellow-400',
    'bg-lime-400',
    'bg-green-500',
    'bg-green-500',
  ];

  const level = Math.ceil(capacity?.value / 20);

  if (!isQuizCompleted) {
    return (
      <StyledCard>
        <div className="flex items-center">
          <div className="relative mr-4">
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 tracking-tighter text-lg font-bold">
              0%
            </span>
            <ProgressChart porcentage={0}></ProgressChart>
          </div>
          <StyledDot className={backgrounds[0]} />

          <Typography
            variant="h5"
            className="ml-2 text-xl not-italic font-bold"
          >
            {capitalize(capacity.aspect)}
          </Typography>
        </div>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <div className="flex items-center">
        <div className="relative mr-4">
          <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 tracking-tighter text-lg font-bold">
            {capacity.value}%
          </span>
          <ProgressChart porcentage={capacity.value}></ProgressChart>
        </div>
        <StyledDot className={backgrounds[level]} />

        <Typography variant="h5" className="ml-2 text-xl not-italic font-bold">
          {capitalize(capacity.aspect)}
        </Typography>
      </div>
    </StyledCard>
  );
};

const Capacities = (props: any) => {
  const { capacities, isFetching, isQuizCompleted } = props;
  const { userAccountInfo } = useUser();
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = React.useState<any>([]);
  const [selectedCapacities, setSelectedCapacities] = React.useState<any>([]);
  const [filteredCapacities, setFilteredCapacities] = React.useState<any>([]);
  const [selectedThemes, setSelectedThemes] = React.useState<any>([]);
  const [selectedScope, setSelectedScope] = React.useState<any>({ 
    label: 'Mis competencias', 
    value: 'my_competencies' 
  });

  const labels = capacities
    ? capacities.map((c: any) => c.capacity)
    : [...Array(5)];
  const values = capacities
    ? capacities.map((c: any) => c.global_value)
    : [...Array(5)];

  const themes = Array.from(new Set(capacities?.map((c: any) => c.theme))).map(
    (t: any) => {
      return {
        label: t,
        value: t,
      };
    }
  );

  const [data, setData] = React.useState<any>({
    labels: labels,
    datasets: [
      {
        label: 'My capacities',
        data: values,
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
        borderColor: 'rgba(255, 255, 255, 0.80)',
        borderWidth: 3,
      },
    ],
  });
  const roles = [
    {
      label: 'Nivel 1',
      value: 'Nivel 1',
    },
    {
      label: 'Nivel 2',
      value: 'Nivel 2',
    },
    {
      label: 'Nivel 3',
      value: 'Nivel 3',
    },
    {
      label: 'Nivel 4',
      value: 'Nivel 4',
    },
    {
      label: 'Nivel 5',
      value: 'Nivel 5',
    },
    {
      label: 'Cross-Level (All)',
      value: 'Cross-Level (All)',
    }
  ];

  useEffect(() => {
    setSelectedRoles([]);
    if (props.capacities) {
      const filtered = props.capacities.filter((capacity: any) => 
        userAccountInfo?.capacity.includes(capacity.capacity)
      );
      setFilteredCapacities(filtered);
      drawChart(filtered, []);
    }
  }, [props, userAccountInfo]);

  const drawChart = (capacities: any[], roles: any[]) => {
    if (!capacities) return;
    const labels = capacities
      ? capacities.map((c: any) => c.capacity)
      : [...Array(5)];
    const values = capacities
      ? capacities.map((c: any) => c.global_value)
      : [...Array(5)];

    const datasets = [];
    datasets.push({
      label: 'My capacities',
      data: values,
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
      borderColor: 'rgba(255, 255, 255, 0.80)',
      borderWidth: 3,
    });

    // random number between 0 and 255

    roles.forEach((role: any) => {
      const benchmark = BENCHMARK[role.value as keyof typeof BENCHMARK];
      const capacities = labels.map((c: any) => {
        const capacity = benchmark.find((b: any) => b.name === c);
        return {
          capacity: c,
          value: capacity ? capacity.value * 20 : 50 + Math.random() * 50,
        };
      });
      data.datasets.filter((d: any) => roles.includes(d.label));
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const values = capacities.map((c: any) => c.value);
      datasets.push({
        label: role.label,
        data: values,
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.10)`,
        borderColor: `rgba(${r}, ${g}, ${b}, 0.80)`,
        borderWidth: 3,
      });
    });

    setData({
      labels: labels,
      datasets: datasets,
    });
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        events: ['click'],
        onClick: (e: any) => {
          console.log(e);
        },
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          display: false,
        },
        angleLines: {
          color: 'rgba(255, 255, 255, 0.20)',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.20)',
        },
        pointLabels: {
          color: 'white',
        },
      },
    },
  };

  const handleFilterChange = (evt: any, type: string) => {
    const filters = {
      capacities: selectedCapacities.map((e: any) => e.value),
      roles: selectedRoles,
      themes: selectedThemes.map((e: any) => e.value),
      scope: selectedScope?.value
    };

    if (type === 'capacities') {
      setSelectedCapacities(evt);
      filters.capacities = evt.map((e: any) => e.value);
    } else if (type === 'roles') {
      setSelectedRoles(evt);
      filters.roles = evt;
    } else if (type === 'themes') {
      setSelectedThemes(evt);
      filters.themes = evt.map((e: any) => e.value);
    } else if (type === 'scope') {
      setSelectedScope(evt);
      filters.scope = evt?.value;
    }

    let filtered = capacities;

    // First apply scope filter if it exists
    if (filters.scope === 'my_competencies') {
      filtered = filtered.filter((capacity: any) => 
        userAccountInfo?.capacity.includes(capacity.capacity)
      );
    }

    // Then apply the rest of the filters
    filtered = filtered.filter((d: any) => {
      const capacityMatch =
        filters.capacities.length === 0 ||
        filters.capacities.some((v: any) => d.details[v] > 0);
      const themeMatch =
        filters.themes.length === 0 ||
        filters.themes.some((v: any) => d.theme === v);
      return capacityMatch && themeMatch;
    });

    setFilteredCapacities(filtered.length ? filtered : capacities);
    drawChart(filtered.length ? filtered : capacities, filters.roles);
  };

  const handleCapacitiesChange = (evt: any) => handleFilterChange(evt, 'capacities');
  const handleRoleChange = (evt: any) => handleFilterChange(evt, 'roles');
  const handleThemesChange = (evt: any) => handleFilterChange(evt, 'themes');
  const handleScopeChange = (evt: any) => handleFilterChange(evt, 'scope');

  const KeyCompetencies = ({ capacities }: any) => {
    const userCapacities = capacities || [];

    return (
      <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4">Mis competencias clave</h2>
        <div className="bg-white/5 rounded-2xl p-8 w-full">
          <div className="flex flex-wrap gap-3 justify-center items-center">
            {userCapacities.map((capacity: any) => (
              <button
                key={capacity}
                className="px-4 py-2 rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
              >
                {capacity}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <h2 className="text-3xl not-italic font-bold">Mis competencias</h2>
      <div
        className={
          'my-5 flex justify-around gap-8 flex-wrap ' +
          (isQuizCompleted && isFetching ? 'animate-pulse' : '')
        }
      >
        {isQuizCompleted && (
          <div className="w-full flex flex-col md:flex-row items-center">
            <div className="p-5">
              <div className="mb-6">
                <label
                  className="block mb-2 text-sm text-gray-300"
                  htmlFor="username"
                >
                  Nivel
                </label>

                <SelectInput
                  isMulti
                  id="roles"
                  name="roles"
                  size="lg"
                  value={selectedCapacities}
                  onChange={handleCapacitiesChange}
                  placeholder="Seleccionar una opción"
                  options={[
                    { label: 'Estratégico', value: 'strategic' },
                    { label: 'Táctico', value: 'tactic' },
                    { label: 'Operativo', value: 'operative' },
                  ]}
                ></SelectInput>
              </div>

              <div className="mb-6">
                <label
                  className="block mb-2 text-sm text-gray-300"
                  htmlFor="username"
                >
                  Benchmark
                </label>

                <SelectInput
                  isMulti
                  id="roles"
                  name="roles"
                  size="lg"
                  value={selectedRoles}
                  onChange={handleRoleChange}
                  placeholder="Seleccionar una opción"
                  options={roles}
                ></SelectInput>
              </div>
            </div>
            <div className="h-[600px] flex-grow flex justify-center">
              {isQuizCompleted && data && (
                <Radar data={data} options={options} />
              )}
            </div>

            <div className="p-5">
              <div className="mb-6">
                <label
                  className="block mb-2 text-sm text-gray-300"
                  htmlFor="username"
                >
                  Competencia
                </label>

                <SelectInput
                  isMulti
                  id="roles"
                  name="roles"
                  size="lg"
                  value={selectedThemes}
                  onChange={handleThemesChange}
                  placeholder="Seleccionar una opción"
                  options={themes}
                ></SelectInput>
              </div>

              <div className="mb-6">
                <label
                  className="block mb-2 text-sm text-gray-300"
                  htmlFor="username"
                >
                  Alcance
                </label>

                <SelectInput
                  id="alcance"
                  name="alcance"
                  size="lg"
                  value={selectedScope}
                  onChange={handleScopeChange}
                  placeholder="Seleccionar una opción"
                  options={[
                    { label: 'Todas', value: 'all' },
                    { label: 'Mis competencias', value: 'my_competencies' },
                    { label: 'Quiz Empresa', value: 'company_quiz' },
                  ]}
                ></SelectInput>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isQuizCompleted && (
        <div className="flex justify-around gap-8 flex-wrap mb-3 p-5 border border-tertiary bg-tertiary/30 rounded-lg">
          ¡Desbloquea tu máximo potencial! Realiza nuestro quiz digital para
          ayudarnos a entender tus necesidades y mejorar tus habilidades.
        </div>
      )}
      <div className="flex items-center justify-center gap-4 mt-8">
        <p className="text-base">
          Escoge otras competencias sobre las cuales quieras completar el Quiz:
        </p>
        <Button
          type="submit"
          chevron
          variant="secondary"
          onClick={() => {
            navigate('./selector');
          }}
        >
          Selecciona un Quiz
        </Button>
      </div>
      <KeyCompetencies capacities={userAccountInfo?.capacity} />
    </>
  );
};

export default Capacities;
